<template>
    <div>
        <h1>Pricing</h1>
        <table v-if="data.pricing" class="table">
            <thead>
                <tr>
                    <th>Price Level</th>
                    <th class="number" v-for="(pricing) in data.pricing[0].build_times" v-bind:key="pricing.build_time_id">
                        {{ pricing.label }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="pricing in data.pricing" v-bind:key="pricing.price_level_id">
                    <td>{{ pricing.price_level }}</td>
                    <td v-for="buildTimes in pricing.build_times" v-bind:key="buildTimes.build_time_id">
                        <input
                            type="text"
                            class="form-control number"
                            v-model="buildTimes.price"
                            @keydown.enter="save(buildTimes, pricing.price_level_id)">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js';

    export default {
        name: 'Details',
        props: ['data'],
        data() {
            return {
                state: store.state
            }
        },
        methods: {
            save: function(buildTime, priceLevelID) {
                let options = {
                    'params[price_level_id]': priceLevelID,
                    'params[build_time_id]': buildTime.build_time_id,
                    'params[price]': buildTime.price
                };

                store.apiWithObjectLoad('save_price', options).then(function () {
                    store.getHistory();
                });
            }
        }
    }
</script>

<style scoped>

</style>